@import '../css/Shared';

.index-page {
    .logo-container {
        display: flex;
        flex-direction: column;

        margin-top: 5em;
    }

    .logo-image {
        height: 20vmin;
        pointer-events: none;
    }

    .logo-title {
        margin: 0;
    }

    .app-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: flex-end;

        width: 100%;
        margin-top: 50px;
    }
}