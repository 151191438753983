.index-bunny-member
{
    h2 {
        margin-top: 50px;
    }

    .links-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: flex-end;

        width: 100%;
        margin-top: 50px;
    }
}

