.bunny-home-button
{
    margin-top: 75px;
    margin-bottom: 75px;

    & img
    {
        transform: scaleY(-1);
    }

    & a
    {
        text-decoration: none;
        color: inherit;
    }
}