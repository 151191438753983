// Background colors
$green: #008000;
$lightGreen: #90ee90;

$bg-color-purple-light: #884c97;
$bg-color-purple: #412848;
$bg-color-black: #010101;

$ganyu-purple: #755EA8;
$ganyu-orange: #DB7288;
$ganyu-yellow: #FFCEBA;

// Buttons
$orange-warning: #ff8d00;
$red-danger: #c10000;
$grey-disabled: grey;
$green-good: #548763;

// Fonts
$font-family-bunny-logo: 'Abril Fatface', cursive;
$font-family-default: 'Noto Sans JP', 'Open Sans', sans-serif;

// $font-size-title: 5vmin;
$font-size-title: 3rem;
$font-size-text: 1rem;
//$font-size-text: 1.8vmin;
