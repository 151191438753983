@import "../css/Shared.scss";

$card-margin: 25px;

.link-card-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    margin-left: $card-margin;
    margin-right: $card-margin;

    width: 125px;

    color: inherit;
    text-decoration: none;

    img
    {
        width: 5rem;
    }

    b
    {
        margin-top: 10px;
        font-size: 1rem;
        font-weight: normal;
    }

}