@import './css/Shared.scss';

html
{
  display: flex;
  justify-content: center;
  font-size: $font-size-text;
  font-family: $font-family-default;
  font-weight:  100;

  height: 100%;
  // background: #320540;
  // background: -webkit-radial-gradient(center, $bg-color-purple, #010101);
  // background: -moz-radial-gradient(center, $bg-color-purple, #010101);
  // background: radial-gradient(ellipse at center, $bg-color-purple, #010101);

  background: $ganyu-purple;
  background-image: linear-gradient(
    to right bottom,
     #755ea8,
     #905fa5,
     #a660a1,
     #ba639a,
     #ca6893,
     #d57291,
     #de7d8f,
     #e6898f,
     #ed9a96,
     #f4aca0,
     #fabdac,
     #ffceba);
  background-attachment: fixed;
}

body
{
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  h1, h2, h3, h4
  {
    font-family: $font-family-bunny-logo;
    font-weight: normal;
  }

  h1
  {
    font-size: $font-size-title;
  }

  h2
  {
    font-size: calc($font-size-title) - 0.75rem;
  }

  h3
  {
    font-size: calc($font-size-title) - 1.25rem;
  }

  h4
  {
    font-size: calc($font-size-title) - 1.75rem;
  }
}

header
{
  color: white;
}

#root
{
  display: flex;
  height: 100%;
  width: 100%;
}

.app
{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app-content
{
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
