.home-button
{
    margin-top: 75px;
    margin-bottom: 75px;

    & a
    {
        text-decoration: none;
        color: inherit;
    }
}