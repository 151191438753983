@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

@import "./Shared.scss";

// Disable Chrome auto-outline
// see: https://stackoverflow.com/a/3397158
*:focus
{
    outline: none;
}

.global-noAnchorDecoration
{
    color: inherit;
    text-decoration: none;
}

.global-default-page-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;

    form
    {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 600px;

        label
        {
            margin-top: 2.5rem;
            margin-bottom: 1rem;
            font-size: 1.25rem;
        }

        .subLabel
        {
            margin-top: 0;
            font-size: 0.9rem;
        }

        select, textarea, input
        {
            border-radius: 7px;
            border-width: 0;
            font-family: $font-family-default;
        }

        select
        {
            height: 2rem;
        }

        textarea
        {
            min-height: 200px;
            padding: 0.5rem;
        }

        input
        {
            height: 2rem;
            padding-left: 5px;

        }
    }
}