.admin-reactions-messages
{
    .buttonsRow
    {
        display: flex;
    }

    button
    {
        margin-left: 10px;
    }
}