@import '../css/Shared.scss';

.bh-button {
    color: white;
    padding: 10px;

    border: 5px;
    border-width: 5px;
    border-style: solid;

    height: 55px;
    width: fit-content;
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    cursor: default;
    padding: 0.65rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 500px;
    transition: transform 0.1s;

    margin-top: 1rem;
    margin-bottom: 1rem;

    font-size: 1rem;

    .textWithImage
    {
        margin-left: 5px;
    }

    & img
    {
        height: 24px;
    }

    &, &:hover, &:visited {
        color: inherit;
        text-decoration: none;
    }

    &:hover {
        // background-color: $bg-color-purple-light;
        // border-color: $bg-color-purple-light;

        transform: scale(1.05);
    }
}

// Colors

.bh-button-primary
{
    border-color: $bg-color-purple-light;
    background-color: $bg-color-purple-light;
}

.bh-button-warning
{
    border-color: $orange-warning;
    background-color: $orange-warning;
}

.bh-button-danger
{
    border-color: $red-danger;
    background-color: $red-danger;
}

.bh-button-disabled
{
    border-color: $grey-disabled;
    background-color: $grey-disabled;

    &:hover {
        transform: none;
    }
}

.bh-button-good
{
    border-color: $green-good;
    background-color: $green-good;
}
